<template lang="pug">
	.content
		.b-card-clubhouse.b-card-clubhouse-gray.mx-auto.text-center
			.wrapper-content
				h2.landing-h1 А еще Paywall:

				.img-desc-list
					.img-desc-list--item(v-for='(i, index) in items' :key='i.title' :item='i')
						.img-desc-list--item--icon-bg
							img.img-desc-list--item--icon(:src='i.icon')
						.img-desc-list--item--desc(v-html='i.desc')

				.b-card-clubhouse.text-left
					.media
						.man-wrapper
							.man
							//-img.man-icon(src='/assets/img/paywall-orange.svg')
						div
							.man-text Начните сейчас! Это бесплатно и быстро, оплата комиссии происходит только по факту заработанных денег.
							.need-help-desc Нужна помощь?
								br.d-sm-none
								|  Пишите в поддержку
								a(:href='botSupport' target='_blank')
									strong  @{{$t('contacts.support_tg_bot')}}
</template>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.b-card-clubhouse-gray {
    padding: 100px 15px 100px;

    .b-card-clubhouse {
        margin: 0 auto;
        padding: 90px 130px 125px 85px;
        width: auto;
    }
}

.landing-h1 {
    margin-bottom: 80px;
}

.img-desc-list {
    margin: 100px 0;
}

.img-desc-list--item {
    padding-left: 10px;
    padding-right: 10px;

    &:nth-child(1) {
        .img-desc-list--item--icon {
            margin: 13px 0 0 13px;
        }
    }

    &:nth-child(2) {
        .img-desc-list--item--icon {
            margin: -1px 0 0 16px;
        }
    }

    &:nth-child(3) {
        .img-desc-list--item--icon {
            margin-right: 10px;
        }
    }

    &:nth-child(4) {
        .img-desc-list--item--icon {
            margin: -12px 0 0 12px;
        }
    }
}

.man-wrapper {
    position: relative;
    margin-right: 150px;
}

.man {
    width: 220px;
    height: 220px;
}

.man-icon {
    width: 83px;
    height: 83px;
    position: absolute;
    bottom: -20px;
    right: -20px;
}

.man-text {
    font-size: 40px;
    font-weight: 500;
    line-height: 120%;
    color: var(--lColor8);
    margin: 10px 0 40px;
}

.need-help-desc {
    color: var(--lColor9);
}

@media (min-width: 1200.01px) and (max-width: 1400px) {
    .b-card-clubhouse-gray {
        padding: 70px 15px 100px;

        .b-card-clubhouse {
            padding: 70px;
        }
    }

    .landing-h1 {
        margin-bottom: 30px;
    }

    .img-desc-list {
        margin-top: 80px;
    }

    .how-to-do-it-btn {
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .img-desc-list--item {
        width: 220px;
    }

    .man-wrapper {
        margin-right: 80px;
    }

    .man {
        width: 180px;
        height: 180px;
    }

    .man-icon {
        width: 67px;
        height: 67px;
    }

    .man-text {
        font-size: 36px;
        margin: 0 0 30px;
    }
}

@media (min-width: 768.01px) and (max-width: 1200px) {
    .b-card-clubhouse-gray {
        padding: 40px 15px 20px;

        .b-card-clubhouse {
            padding: 60px 70px;
        }
    }

    .img-desc-list {
        margin-top: 70px;
    }

    .how-to-do-it-btn {
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .man-wrapper {
        margin-right: 100px;
    }

    .man {
        width: 145px;
        height: 145px;
    }

    .man-icon {
        width: 67px;
        height: 67px;
    }

    .man-text {
        font-size: 28px;
        margin: 0 0 25px;
    }
}

@media (min-width: 768.01px) and (max-width: 1024px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 767.98px) {
    .b-card-clubhouse-gray {
        padding: 40px 15px 20px;

        .b-card-clubhouse {
            margin-left: 0;
            margin-right: 0;
            padding: 30px 40px 35px;
            text-align: center !important;

            .media {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .landing-h1 {
        margin-bottom: 15px;
    }

    .img-desc-list {
        margin: 35px 20px 50px;
    }

    .how-to-do-it-btn {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .img-desc-list--item {
        padding-left: 0;
        padding-right: 0;

        &:nth-child(3) {
            .img-desc-list--item--icon {
                margin-top: -5px;
            }
        }

        &:nth-child(4) {
            .img-desc-list--item--icon {
                margin-top: -5px;
                margin-left: -5px;
            }
        }
    }

    .man-wrapper {
        margin-right: 0;
        margin-bottom: 25px;
    }

    .man {
        width: 145px;
        height: 145px;
    }

    .man-icon {
        width: 40px;
        height: 40px;
        right: -10px;
        bottom: -10px;
    }

    .man-text {
        font-size: 18px;
        margin: 0 0 15px;
    }
}
</style>

<script>
import { DEVICE } from '@/utils/device';

export default {
    name: 'AndMore',
    components: {},
    mounted() {},
    data: () => ({
        isScreenSmall: DEVICE.isMobile() || DEVICE.isTablet(),
        isTablet: DEVICE.isTablet(),
        items: [
            /*{icon: '/assets/img/more1.svg', desc: 'Вовремя спишет сумму подписки с пользователей'},
				{icon: '/assets/img/more2.svg', desc: 'Добавит в канал оплативших пользователей и после пары напоминаний удалит неоплативших'},
				{icon: '/assets/img/more3.svg', desc: 'Оплатит за вас банковские комиссионные за вывод'},
				{icon: '/assets/img/more4.svg', desc: 'Уведомит вас о каждом новом подписчике и покажет подробную статистику и метрики'},
				{icon: '/assets/img/more5.svg', desc: 'Вам остается только делать контент и привлекать свою аудиторию'}*/
        ],
    }),
    computed: {},

    beforeDestroy() {},

    methods: {},
};
</script>
