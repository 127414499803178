<template lang="pug">
	.content#howtodoit
		.b-card-clubhouse.b-card-clubhouse-gray.text-center.mx-auto
			.wrapper-content
				h2.landing-h1 Как это сделать?
				.landing-desc Настройка не займет больше пяти минут

				.img-desc-list
					.img-desc-list--item(v-for='(i, index) in items' :key='i.img')
						.img-desc-list--item--icon-bg
							img.img-desc-list--item--icon(:src='i.icon')
							.img-desc-list--item--number 0{{ index + 1 }}
						.img-desc-list--item--desc(v-html='i.desc')

				a.how-to-do-it-btn(:href='loginUrl')
					b-btn.landing-btn(variant='primary' size='xl') 🎉 &nbsp;Перейти в сервис
				.need-help-desc Нужна помощь? Пишите в поддержку
					a(:href='botSupport' target='_blank')
						strong  @{{$t('contacts.support_tg_bot')}}
</template>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.b-card-clubhouse {
    margin-top: -215px;
    padding: 330px 15px 150px;
    position: relative;
    z-index: 1;
}

.landing-h1 {
    margin-bottom: 25px;
}

.how-to-do-it-btn {
    display: block;
    margin-top: 100px;
    margin-bottom: 40px;
}

.img-desc-list--item {
    &:nth-child(2) {
        .img-desc-list--item--icon {
            margin-top: 10px;
        }
    }

    &:nth-child(3) {
        .img-desc-list--item--icon {
            margin-left: 12px;
        }
    }

    &:nth-child(5) {
        .img-desc-list--item--icon {
            margin-right: 5px;
        }
    }
}

@media (min-width: 1200.01px) and (max-width: 1400px) {
    .b-card-clubhouse {
        margin-top: -180px;
        padding: 280px 15px 100px;
    }

    .landing-h1 {
        margin-bottom: 30px;
    }

    .img-desc-list {
        margin-top: 80px;
    }

    .how-to-do-it-btn {
        margin-top: 80px;
        margin-bottom: 30px;
    }
}

@media (min-width: 768.01px) and (max-width: 1200px) {
    .b-card-clubhouse {
        margin-top: -150px;
        padding: 230px 15px 85px;
    }

    .img-desc-list {
        margin-top: 70px;
    }

    .how-to-do-it-btn {
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .img-desc-list--item {
        &:nth-child(2) {
            .img-desc-list--item--icon {
                margin-left: 3px;
            }
        }
    }
}

@media (min-width: 768.01px) and (max-width: 1024px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 767.98px) {
    .b-card-clubhouse {
        margin-top: -160px;
        padding: 260px 35px 35px;
    }

    .landing-h1 {
        margin-bottom: 15px;
    }

    .img-desc-list {
        margin-top: 35px;
    }

    .how-to-do-it-btn {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .img-desc-list--item {
        &:nth-child(3) {
            .img-desc-list--item--icon {
                margin-top: -5px;
            }
        }

        &:nth-child(4) {
            .img-desc-list--item--icon {
                margin-top: -5px;
                margin-left: -5px;
            }
        }
    }
}
</style>

<script>
import { DEVICE } from '@/utils/device';
export default {
    name: 'HowToDoIt',
    components: {},
    mounted() {},
    data: () => ({
        isScreenSmall: DEVICE.isMobile() || DEVICE.isTablet(),
        isTablet: DEVICE.isTablet(),
        items: [
            /*{icon: '/assets/img/howto1.svg', desc: 'Создайте закрытый чат<br>или канал в телеграм'},
				{icon: '/assets/img/howto2.svg', desc: 'Подключите Paywall,<br> и бот сгенерирует ссылку<br> на лендинг'},
				{icon: '/assets/img/howto3.svg', desc: 'Расскажите о проекте<br> в своем блоге, или<br> в Clubhouse'},
				{icon: '/assets/img/howto4.svg', desc: 'Создавайте крутые комнаты<br> и собирайте свою аудиторию'},
				{icon: '/assets/img/howto5.svg', desc: 'Получайте разовую, либо<br> регулярную оплату<br> от ваших пользователей'}*/
        ],
    }),
    computed: {},

    beforeDestroy() {},

    methods: {},
};
</script>
