<template lang="pug">
	.intro#whatisit
		.content
			.content.text-center
				.wrapper-content
					//-img.banner-intro(src='/assets/img/intro-ch.png')
					h1.landing-h1 Начни зарабатывать
						br
						|  в Clubhouse с Paywall уже сейчас
					.landing-desc Создай закрытый платный чат в телеграм, собери в нем аудиторию
						br
						|  для комнаты, размести в чате ссылку
					.btn-wrapper
						a(href='https://youtu.be/LwXe_suBuvo' target='_blank')
							b-btn.landing-btn(variant='primary' size='xl') 🎉 &nbsp;Как это сделать
</template>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.intro {
    padding-bottom: 300px;
}

.banner-intro {
    max-width: 1080px;
    width: 100%;
}

.landing-h1 {
    margin-top: 96px;
    margin-bottom: 55px;
}

.landing-desc {
    margin-bottom: 60px;
}

@media (min-width: 1200.01px) and (max-width: 1400px) {
    .intro {
        padding-bottom: 250px;
    }

    .landing-h1 {
        margin-top: 47px;
        margin-bottom: 42px;
    }
}

@media (min-width: 768.01px) and (max-width: 1200px) {
    .intro {
        padding-bottom: 180px;
    }

    .banner-intro {
        max-width: 80%;
    }

    .landing-h1 {
        font-size: 43px;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .landing-desc {
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .intro {
        padding-bottom: 150px;
    }
    .banner-intro {
        max-width: 740px;
        width: 740px;
        margin-left: -45%;
    }
    .landing-h1 {
        font-size: 30px;
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .landing-desc {
        font-size: 14px;
        margin-bottom: 35px;
    }
}
</style>

<script>
import { ENV } from '@/api/index';
import { DEVICE } from '@/utils/device';

export default {
    name: 'Intro',
    components: {},
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    data: () => ({
        isScreenSmall: DEVICE.isMobile() || DEVICE.isTablet(),
        isTablet: DEVICE.isTablet(),
    }),
    computed: {},

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        onResize() {
            this.isScreenSmall = DEVICE.isMobile() || DEVICE.isTablet();
        },
    },
};
</script>
