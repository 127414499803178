<template lang="pug">
	.content#whatisitpaywall
		.wrapper-content
			.b-card-clubhouse.text-center.mx-auto
				.man
				h2.landing-h2 Кто такой и зачем
					br.d-sm.none
					|  нужен Paywall
				.landing-desc Paywall - сервис, который в несколько кликов подключается к закрытому телеграм каналу и предоставляет доступ пользователям по подписке. Авторы контента используют это в дополнение к своим медийным проектам и блогам, собирают вовлеченную аудиторию, получают дополнительный доход.
</template>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';
.b-card-clubhouse {
    max-width: 1142px;
    padding: 160px 220px 100px;
    position: relative;
    z-index: 2;
}

.man {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.landing-desc {
    font-size: 22px;
}

@media (min-width: 1200.01px) and (max-width: 1400px) {
    .b-card-clubhouse {
        max-width: 950px;
        padding-left: 85px;
        padding-right: 85px;
    }

    .landing-desc {
        font-size: 20px;
    }
}

@media (min-width: 768.01px) and (max-width: 1200px) {
    .b-card-clubhouse {
        max-width: 800px;
        padding: 130px 105px 75px;
    }

    .landing-desc {
        font-size: 16px;
    }

    .man {
        width: 160px;
        height: 160px;
    }
}

@media (min-width: 768.01px) and (max-width: 1024px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 767.98px) {
    .b-card-clubhouse {
        max-width: 100%;
        padding: 115px 18px 30px 19px;
    }

    .landing-desc {
        font-size: 14px;
    }

    .man {
        width: 120px;
        height: 120px;
        transform: translate(-50%, -30%);
    }
}
</style>

<script>
import { DEVICE } from '@/utils/device';

export default {
    name: 'WhatIsIt',
    components: {},
    mounted() {},
    data: () => ({
        isScreenSmall: DEVICE.isMobile() || DEVICE.isTablet(),
        isTablet: DEVICE.isTablet(),
    }),
    computed: {},

    beforeDestroy() {},

    methods: {},
};
</script>
