<template lang="pug">
	#howitworks
		.content
			.wrapper-content
				h2.landing-h1.text-center Сделать закрытую комнату по подписке в Clubhouse просто
				.row
					.col-md-6.col-xs-12
						//-img#room(src='/assets/img/room.png')
					.col-md-6.col-xs-12
						.list--circle.list--circle-white
							.list--circle--item(v-for='(i, index) in items')
								.media.align-items-center
									.list--circle--item--number 0{{index+1}}
										.list--circle--item--number--icon(v-if='i.icon')
											img(:src='i.icon')
									.list--circle--item--desc.media-body(v-html='i.desc')

						//-.text-center.text-md-left
							a(href='https://youtu.be/LwXe_suBuvo' target='_blank')
								b-btn.landing-btn(variant='primary' size='xl') Смотреть скринкаст
</template>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.content {
    padding-top: 150px;
    padding-bottom: 90px;
}

.landing-h1 {
    margin-bottom: 100px;
}

#room {
    width: 85%;
}

.list--circle {
    margin-bottom: 70px;
}

.list--circle-white {
    .list--circle--item--number {
        background: #fff;
    }
}

.list--circle--item {
    display: block;
    margin-bottom: 45px;

    &:last-child {
        margin-bottom: 0;
    }
}

.list--circle--item--number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40%;
    color: var(--lColor1);
    font-size: 24px;
    width: 60px;
    height: 60px;
    margin-right: 45px;
    position: relative;
}

.list--circle--item--number--icon {
    position: absolute;
    top: -15px;
    right: -7px;
}

.list--circle--item--desc {
    color: var(--lColor8);
    font-size: 18px;
    line-height: 130%;
}

@media (min-width: 1200.01px) and (max-width: 1400px) {
    .content {
        padding-top: 100px;
        padding-bottom: 80px;
    }

    .landing-h1 {
        margin-bottom: 80px;
    }

    #room {
        width: 95%;
    }

    .list--circle {
        margin-bottom: 60px;
    }

    .list--circle--item {
        margin-bottom: 40px;
    }

    .list--circle--item--number {
        margin-right: 40px;
    }

    .list--circle--item--desc {
        font-size: 16px;
    }
}

@media (min-width: 768.01px) and (max-width: 1200px) {
    .content {
        padding-top: 90px;
        padding-bottom: 70px;
    }

    .landing-h1 {
        margin-bottom: 70px;
    }

    #room {
        width: 100%;
    }

    .list--circle {
        margin-bottom: 50px;
    }

    .list--circle--item {
        margin-bottom: 40px;
    }

    .list--circle--item--number {
        font-size: 20px;
        margin-right: 22px;
        width: 50px;
        height: 50px;
    }

    .list--circle--item--desc {
        font-size: 14px;
    }
}

@media (max-width: 767.98px) {
    .content {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    .landing-h1 {
        margin-bottom: 40px;
    }

    #room {
        width: 100%;
        margin-bottom: 40px;
    }

    .list--circle {
        margin-bottom: 40px;
    }

    .list--circle--item {
        margin-bottom: 35px;
    }

    .list--circle--item--number {
        font-size: 18px;
        margin-right: 25px;
        width: 45px;
        height: 45px;
    }

    .list--circle--item--desc {
        font-size: 12px;
    }

    .landing-btn {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>

<script>
export default {
    name: 'Works',
    components: {},
    data: () => ({
        items: [
            { desc: 'Создаете закрытый чат или канал в телеграм<br> и добавляете в него бота @paywall_manager_bot' },
            { desc: 'Настраиваете тарифы доступа и описание<br> вашего проекта' },
            { desc: 'Размещаете в вашем канале ссылку<br> на ваш аккаунт Clubhouse' },
            { desc: 'Оплативший подписку пользователь попадает<br> в канал, подписывается на ваш аккаунт в Clubhouse' },
            /*{desc: 'Анонсируете закрытую комнату и добавляете<br> участников по списку из закрытого канала', icon: '/assets/img/star.svg'},*/
        ],
    }),
    computed: {},
    methods: {
        playVideo() {
            // this.$refs.modalVideo.show();
            this.$nextTick(() => {
                this.$refs.video.play();

                if (this.$refs.video.requestFullscreen) {
                    this.$refs.video.requestFullscreen();
                } else if (this.$refs.video.mozRequestFullScreen) {
                    /* Firefox */
                    this.$refs.video.mozRequestFullScreen();
                } else if (this.$refs.video.webkitRequestFullscreen) {
                    /* Chrome, Safari and Opera */
                    this.$refs.video.webkitRequestFullscreen();
                } else if (this.$refs.video.msRequestFullscreen) {
                    /* IE/Edge */
                    this.$refs.video.msRequestFullscreen();
                }
            });
        },
    },

    created() {},
};
</script>
